import React, {useEffect, useState} from "react";
import {graphql, withPrefix, Script, Link} from "gatsby";
import {Helmet} from "react-helmet";
import Layout from "../components/layout";
import {componentMap, DataProvider} from "../contentful/data-provider";
import axios from "axios";
import FormGroup from "../components/elements/formGroup";
import ogimage from "../images/ogimages/careers.png";
import "/src/style/darkmode.scss";

export default function CareersPage({data,location}) {
    const dataProvider = new DataProvider(data);
    const jobs = data.allGreenhouseJob.nodes;

    const params = new URLSearchParams(location.search);
    const jobQuery = params.get("job");

    const [selectedJob, setSelectedJob] = useState(false);

    useEffect(()=>{
        if(jobQuery){
            jobs.map(function(job,index){
                if(job.gh_Id === Number(jobQuery)){
                    setSelectedJob(job);
                }
            })
        }else{
            setSelectedJob(false);
        }
    },[jobQuery])

    function htmlDecode(input){
        var e = document.createElement('div');
        e.innerHTML = input;
        return e.childNodes[0].nodeValue;
    }

    const submitApplication = (e) =>{
        e.preventDefault();
        const url = `https://wandering-rain-2cd4.designatives.workers.dev/`
        //const url = `https://boards-api.greenhouse.io/v1/boards/agoric/jobs/${selectedJob.gh_Id}`

        var apiKey = `e6807e7714b65ef8e8d4ab61fda4e4cb-4`

        var form = document.getElementById('application');
        var data = new FormData(form);

        try {
            const response = axios({
                method: 'post',
                url: url,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Basic ${btoa(apiKey)}`
                },
                withCredentials: false,
                data: data,
            });
        } catch (error) {
            console.error(error);
        }
    }

    ///////
    var Grnhse = Grnhse || {};

    Grnhse.Settings = {
        targetDomain:   'https://boards.greenhouse.io',
        scrollOnLoad:   false,
        autoLoad:       true,
        boardURI:       'https://boards.greenhouse.io/embed/job_board?for=agoric',
        applicationURI: 'https://boards.greenhouse.io/embed/job_app?for=agoric',
        baseURI:        'https://agoric.com/careers/#open-positions',
        iFrameWidth:    '100%'
    };

    var Grnhse = Grnhse || {};

    Grnhse.Const = {
        JOB_ID:       'gh_jid',
        SOURCE_TOKEN: 'gh_src'
    };

    Grnhse.Config = {
        IframeDefault: {
            id:          'grnhse_iframe',
            width:       Grnhse.Settings.iFrameWidth,
            frameborder: '0',
            scrolling:   'no',
            allow:       'geolocation',
            onload:      undefined,
            title:       'Greenhouse Job Board'
        }
    };

    Grnhse.Route = {
        boardUrl: function(source) {
            var helper = Grnhse.UriHelper,
                settings = Grnhse.Settings,
                params = [];

            if (source) {
                params.push(['t', source]);
            }

            return helper.appendParams(settings.boardURI, params);
        },
        applicationUrl: function(source, jobId) {
            var helper = Grnhse.UriHelper,
                settings = Grnhse.Settings,
                params = [];

            if (source) {
                params.push(['t', source]);
            }

            if (jobId) {
                params.push(['token', jobId]);
            }

            return helper.appendParams(settings.applicationURI, params);
        }
    };

    Grnhse.UriHelper = {
        base: function() {
            var uriHelper = Grnhse.UriHelper,
                location = uriHelper.currentLocation(),
                settings = Grnhse.Settings;

            return window && location ? uriHelper.pathFromLocation(location) : settings.boardURI;
        },

        currentLocation: function() {
            return window.top.location;
        },
        getParam: function(name) {
            var location = Grnhse.UriHelper.currentLocation(),
                uri = location.href,
                start = uri.indexOf(name),
                end;

            if (start === -1) {
                return null;
            }

            start += name.length + 1;
            end   = uri.substr(start).search(/(&|#|$|;)/);

            return uri.substr(start, end);
        },
        encodeParam: function (kv) {
            // FIXME: Would be nice to be correct, but Greenhouse breaks.
            // return encodeURIComponent(kv[0]) + '=' + encodeURIComponent(kv[1]);
            return encodeURIComponent(kv[0]) + '=' + kv[1];
        },
        appendParams: function(url, params) {
            var base = Grnhse.UriHelper.base();
            params.push(['b', base]);
            url += (url.indexOf('?') === -1) ? '?' : '&';
            return url + params.map(this.encodeParam).join('&');
        },
        pathFromLocation: function(location) {
            return encodeURIComponent(location.protocol + '//' + location.host + location.pathname);
        }
    };

    Grnhse.BrowserHelper = {
        supportsPostMessage: function() {
            return !(document.all && !window.atob);
        }
    };

    Grnhse.Iframe = function(src, overrides) {
        var settings = Grnhse.Settings,
            self = this;

        overrides = overrides || {};

        this.config = Grnhse.Config.IframeDefault;
        this.config.src = src;

        this.supportAwfulBrowsers();

        // overrides['onload'] = settings.scrollOnLoad ? 'window.scrollTo(0,0)' : undefined;
        location.hash = "#" + "open-positions";

        mergeOverrides.call(this);

        this.registerEventHandlers();
        this.htmlElement = this.build();
        this.render();

        function mergeOverrides() {
            for (var override in overrides) {
                if (overrides.hasOwnProperty(override)) {
                    self.config[override] = overrides[override];
                }
            }
        }
    };

    Grnhse.Iframe.prototype.build = function() {
        var iframe = document.createElement('iframe'),
            config = this.config;

        for (var key in config) {
            if (config.hasOwnProperty(key)) {
                iframe.setAttribute(key, config[key]);
            }
        }

        return iframe;
    };

    Grnhse.Iframe.prototype.render = function() {
        var container = document.getElementById('grnhse_app');

        container.innerHTML = '';
        container.appendChild(this.htmlElement);
    };

    Grnhse.Iframe.prototype.registerEventHandlers = function() {
        var instance = this,
            resizeEvent = null;

        if (window.addEventListener) {
            window.addEventListener('message', resize, false);
            window.addEventListener('resize', windowResize, false);
        } else if (window.attachEvent) {
            window.attachEvent('onmessage', resize);
            window.attachEvent('onresize', windowResize);
        }

        function windowResize(e) {
            // Pass resize event from parent window to iframe
            clearTimeout(resizeEvent);
            resizeEvent = setTimeout(triggerResize, 200);
        }

        function triggerResize() {
            if (window.postMessage && instance.htmlElement) {
                instance.htmlElement.contentWindow.postMessage('resize', '*');
            }
        }

        function resize(e) {
            if (instance.htmlElement && e.origin === Grnhse.Settings.targetDomain && e.data > 0) {
                instance.htmlElement.setAttribute('height', e.data);
            }
        }
    };

    Grnhse.Iframe.prototype.supportAwfulBrowsers = function() {
        var browserHelper = Grnhse.BrowserHelper;

        if (!browserHelper.supportsPostMessage()) {
            this.config['scrolling'] = 'yes';
            this.config['height'] = 1000;
        }
    };

    Grnhse.Iframe.load = function(jobId, source) {
        var r = Grnhse.Route,
            uriHelper = Grnhse.UriHelper,
            jobId = jobId || uriHelper.getParam(Grnhse.Const.JOB_ID),
            source = source || uriHelper.getParam(Grnhse.Const.SOURCE_TOKEN),
            viewingApplication = !!jobId,
            pathToLoad = viewingApplication ? r.applicationUrl(source, jobId) : r.boardUrl(source);

        return new Grnhse.Iframe(pathToLoad);
    };

    var _grnhse = _grnhse || {};
    _grnhse.load = Grnhse.Iframe.load;


    useEffect(()=>{
        selectedJob && Grnhse.Iframe.load(jobQuery);
        if (Grnhse.Settings.autoLoad && selectedJob) {
            setTimeout(function(){
                window.location.hash = "#" + "open-positions";
            },1000)
            window && window.addEventListener('load', Grnhse.Iframe.autoLoad);
        }
    },[selectedJob])

    const enableNewApplication = false;

    return (
        <Layout
            isDifferentLogo={true}>
            <Helmet
                meta={[
                    {
                        property: `og:title`,
                        content: 'Agoric - Careers',
                    },
                    {
                        property: `twitter:title`,
                        content: 'Agoric - Careers',
                    },
                    {
                        property: `og:type`,
                        content: `website`,
                    },
                    {
                        property: `twitter:image`,
                        content: ogimage,
                    },
                    {
                        property: `og:image`,
                        content: ogimage,
                    },
                    {
                        property: `twitter:card`,
                        content: `summary_large_image`,
                    },
                ]}
            >
                <title>Agoric - Careers</title>
            </Helmet>


            <div className={'careers-page'}>
                { dataProvider.getCurrentPageSections().map((section, index) => {
                    return (
                        <>
                            { dataProvider.getElementById(section.id) &&
                            React.createElement(
                                componentMap[section.type],
                                {content: dataProvider.getElementById(section.id), key: index}
                            )}
                        </>
                    );
                }) }
                <section id="open-positions" className="career-section grey">
                    <div className="container">
                        {!selectedJob && <ul className={'jobs'}>
                            {jobs.map(function(job,index){
                                return <li key={index}>
                                    <Link to={`/careers/?job=${job.gh_Id}`}>
                                    <div className={'flex items-center'}>
                                        <div className={'basis-auto'}>
                                            <div className={'title'}>
                                                {job.title}
                                                <span>{job.location.name}</span>
                                            </div>
                                        </div>
                                        <div className={'basis-auto'}/>
                                    </div>
                                    </Link>
                                </li>
                            })}
                        </ul>}

                        {selectedJob && enableNewApplication && <div className={'job-detail'} id={'job-post'}>
                            <div className={'row align-items-center mb-5 justify-content-between'}>
                                <div className={'col-auto'}>
                                    <h3 className={'mb-0'}>{selectedJob.title}</h3>
                                    <span>{selectedJob.location.name}</span>
                                </div>
                                <div className={'col-auto'}>
                                    <Link to={'/careers#open-positions'}>
                                        <button className={'btn btn--small'}>View all jobs</button>
                                    </Link>
                                </div>
                            </div>
                            <div dangerouslySetInnerHTML={{__html:htmlDecode(selectedJob.content)}}/>



                            {enableNewApplication && <div className={'application'}>
                                <form id={'application'} onSubmit={submitApplication}  method={'post'}>
                                    <div className={'row'}>
                                        {selectedJob.questions.map(function(question,index){
                                            return <div className={'col-12 col-md-6'}><FormGroup field={question}/></div>
                                        })}
                                        <div className={'col-12'}>
                                            <button type={'submit'} className={'btn'}>Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>}
                        </div>}

                        <div className={'row justify-content-end'}>
                            <div className={'col-auto'}>
                                <Link to={'/careers#open-positions'}>
                                    <button className={'btn btn--small right'}>View all jobs</button>
                                </Link>
                            </div>
                        </div>
                        {selectedJob && <div id="grnhse_app"/>}

                    </div>
                </section>
            </div>

        </Layout>
    );
}

export const query = graphql`
query {
  contentfulPage(id: {eq: "81914e67-23be-5c03-bb10-dd9c42e5bdc9"}) {
    ... CurrentPageDetails
  }
  allContentfulEntry {
    edges {
      node {
        ... AllEntryDetails
      }
    }
  }
  allGreenhouseJob {
    nodes {
        id
        gh_Id
        internal_job_id
        title
        absolute_url
        content
        updated_at(formatString: "ddd, MMMM Do, YYYY")
        location {
          name
        }
        departments {
          name
        }
      }
    }
    }

`;
